import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { v4 } from "uuid"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import {
  Section,
  SectionTitle,
  SectionLead,
  BreadCrumb,
} from "../components/Section"
import CallNow from "../components/CallNow"
import CustomBuildingQuote from "../components/CustomBuildingQuote"
import Badge from "../components/Badge"
import RelatedProductCarousel from "../components/RelatedProductCarousel"
import { StaticImage } from "gatsby-plugin-image"
import BreakpointUp from "../components/Media/BreakpointUp"
import BreakpointDown from "../components/Media/BreakpointDown"
import ListArrow from "../images/BulletIcon.svg"
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton"
import PrimaryButton from "../components/Button/PrimaryButton"
import RightLineArrowIcon from "../components/Icons/RightLineArrowIcon"



const BackgroundImage = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`
const SectionGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
`
const SectionGridLeft = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.lg`
    flex: 0 0 50%;
    max-width: 50%;
  `}
`
const SectionGridRight = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.lg`
    flex: 0 0 50%;
    max-width: 50%;
  `}
`
const ListBadge = styled.div`
  padding: 0 0 30px 0;
  display: flex;
  flex-wrap: wrap;

  ${BreakpointUp.lg`
    padding:0 30px 30px 0;
  `}
  .badge {
    ${BreakpointDown.sm`
      padding: 4px 15px;
      margin: 3px;
    `}
  }
`
const ListAreas = styled.ul`
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
  column-count: 2;
  column-fill: balance;
  ${BreakpointUp.sm`
        column-count: 3;
    `}
  ${BreakpointUp.lg`
        column-count: 4;
    `}
`
const ListItem = styled.li`
  padding-left: 20px;
  vertical-align: middle;
  margin: 0;
  line-height: 26px;
  + li {
    margin-top: 10px;
  }
  &:before {
    content: url(${ListArrow});
    display: inline-block;
    margin-left: -20px;
    width: 20px;
    vertical-align: top;
  }
  a {
    color: #000;
    font-weight: 700;
    line-height: 26px;
    display: inline-block;
    &:hover {
      color: #fc0002;
    }
  }
`

const MapCaption = styled.div`
  background-color: #0b619b;
  ${BreakpointUp.md`        
    background-color: transparent;
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
  `}
  &:after {
    ${BreakpointUp.md`        
      content: '';
      position: absolute;
      left: 0;
      width: 75%;
      background-color: #0B619B;
      padding: 15px 120px;
      top: 0;
      bottom: 0;
    `}
  }
`
const MapBody = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 15px;
  flex-direction: column;
  ${BreakpointUp.md`        
    flex-direction: row;
  `}
`
const MapTitle = styled.h1`
  color: #fff;
  font-size: 28px;
  line-height: 34px;
  ${BreakpointUp.sm`  
      margin-bottom:0;
      line-height: 40px;
    `}
  br {
    ${BreakpointDown.sm`        
            display:none
        `}
  }
  ${BreakpointUp.md`  
      font-size: 30px;
      line-height: 36px;
    `}

  @media (min-width: 1600px) {
    font-size: 48px;
    line-height: 60px;
  }
`
const ButtonToolbar = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const ServiceArea = ({ data, location }) => {
  const [currentState, setCurrentState] = useState("Alabama")
  const [currentAbbreviation, setCurrentAbbreviation] = useState("AL")
  const [carouselProduct, setCarousleProduct] = useState(
    data.allContentfulProduct.edges
  )

  useEffect(() => {
    var currentData = []
    data.allContentfulProduct.edges.forEach(function (item) {
      if (item.node.installationStates && item.node.installationStates.length > 0) {
        item.node.installationStates.map((state) => {
          if(state.name === currentState) {
            currentData.push(item)
          }
        })
      }
    })
    setCarousleProduct(currentData)
  }, [currentState, setCurrentState])
  const [ limit, setLimit ] = useState(20);
  return (
    <Layout location={location}>
      <Seo title="Our Service Areas Across All United States | Coast To Coast Carports" description="Order Your Steel Buildings, Metal Garages, Metal Barns, Carports, custom metal buildings, and kits from Coast To Coast Carports, We are your online local dealer. Call now." />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <span>Service Area</span>
        </div>
      </BreadCrumb>
      <Section pt="0" pb="0" xpt="30px" xpb="0" bgColor="#fff">
        <BackgroundImage>
          <StaticImage src="../images/service-area.jpg" alt="Service Area" />
        </BackgroundImage>
        <MapCaption>
          <MapBody>
            <MapTitle>We Cover 48 States & 18000+ Cities</MapTitle>
          </MapBody>
        </MapCaption>
      </Section>
      <Section
        pt="90px"
        pb="90px"
        xpt="60px"
        xpb="30px"
        bgColor="#F2F4F9"
        className="circle-left"
      >
        <div className="container">
          <SectionTitle mb="10px">Explore 48 States</SectionTitle>
          <SectionLead mb="40px" maxWidth="900px">
            We have a network of distribution facilities located across the USA
            to better distribute our products.
          </SectionLead>
          <SectionGrid>
            <SectionGridLeft>
              <ListBadge>
                {data.allContentfulState.edges.map(item => (
                  <div
                    className="badge-white"
                    onClick={() => {
                      setCurrentState(item.node.name)
                      setCurrentAbbreviation(item.node.abbreviation)
                      setLimit(20)
                    }}
                    key={v4()}
                    aria-hidden="true"
                  >
                    <Badge badgeText={item.node.name} />
                  </div>
                ))}
              </ListBadge>
            </SectionGridLeft>
            <SectionGridRight>
              <RelatedProductCarousel data={carouselProduct} />
              <ButtonToolbar>
                <Link
                  to={
                    "/metal-buildings-" +
                    currentState.split(" ").join("-").toLowerCase() +
                    "-" +
                    currentAbbreviation.toLowerCase()
                  }
                >
                  <PrimaryLinkButton
                    icon={<RightLineArrowIcon />}
                    text={"Explore " + currentState}
                  />
                </Link>
              </ButtonToolbar>
            </SectionGridRight>
          </SectionGrid>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionTitle mb="20px">
            Explore {currentState} Cities Coast to Coast Carports Serves
          </SectionTitle>
          <SectionLead mb="60px" maxWidth="900px">
            It’s important to us here at Coast to Coast Carports that no matter
            where you’re from, you get the perfect storage solution for both
            your current and anticipated needs.
          </SectionLead>
          <ListAreas>
            {data.allContentfulState.edges
              .filter(state => state.node.name === currentState)
              .map(item => {
                return item.node.cities.slice(0, limit).map(city => {                 
                  return (
                    <ListItem>{city.content}</ListItem>
                  )
                })
              })}
          </ListAreas>            
          <ButtonToolbar>
            {limit !== data.allContentfulState.edges.cities && 
              <button type="button"
              aria-label="Request A Quote" 
              onClick={()=> setLimit(data.allContentfulState.edges.cities)}><PrimaryButton textBefore="Load More" icon={<RightLineArrowIcon />}  /></button>
            }
          </ButtonToolbar>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionTitle maxWidth="800px" mb="40px">
            Talk to Us. We can custom design steel buildings for your needs
          </SectionTitle>
          <CustomBuildingQuote states={data.allContentfulState.edges} location={location} />
        </div>
      </Section>
      <CallNow />
    </Layout>
  )
}

export default ServiceArea

export const pageQuery = graphql`
  query ServiceAreaPageQuery {
    allContentfulProduct {
      edges {
        node {
          metaTitle
          metaDescription
          productSku
          productName
          url
          productDescription {
            childMarkdownRemark {
              html
            }
          }
          featuredProduct
          productCategory {
            name
            url
          }
          productSubCategories {
            name
            url
          }
          productRoofStyle {
            name
          }
          productApplications {
            name
          }
          productImages {
            title
            gatsbyImageData
          }
          width
          height
          length
          price
          applicableForFinancing
          applicableForFreeDelivery
          applicableForRto
          otherDetails {
            childMarkdownRemark {
              html
            }
          }
          installationStates {
            name
            url
          }
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
          abbreviation
          cities {
            content
          }
          locationsForMainBuildingCategories {
            metalBuildingAddress
            metalBuildingContactNumber
            metalBuildingLocationLink
            metalBuildingMapEmbedLink {
              metalBuildingMapEmbedLink
            }
            metalCarportsAddress
            metalCarportContactNumber
            metalCarportLocationLink
            metalCarportMapEmbedLink {
              metalCarportMapEmbedLink
            }
            metalGarageAddress
            metalGarageContactNumber
            metalGarageLocationLink
            metalGarageMapEmbedLink {
              metalGarageMapEmbedLink
            }
            rvCarportsAddress
            rvCarportsContactNumber
            rvCarportsLocationLink
            rvCarportsMapEmbedLink {
              rvCarportsMapEmbedLink
            }
          }
          locationForCities {
            cityName
            metalBuildingAddress
            metalBuildingContactNumber
            metalBuildingLocationLink
            metalBuildingMapEmbedLink {
              metalBuildingMapEmbedLink
            }
            metalCarportAddress
            metalCarportContactNumber
            metalCarportLocationLink
            metalCarportMapEmbedLink {
              metalCarportMapEmbedLink
            }
            metalGarageAddress
            metalGarageContactNumber
            metalGarageLocationLink
            metalGarageMapEmbedLink {
              metalGarageMapEmbedLink
            }
            rvCarportAddress
            rvCarportContactNumber
            rvCarportLocationLink
            rvCarportMapEmbedLink {
              rvCarportMapEmbedLink
            }
          }
        }
      }
    }
  }
`
